import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {Project} from '../../api'
import {deleteProjectApi, projectListApi} from './projectListApi'
import {message} from 'antd'

export interface ProjectListState {
    list: Project[]
}

const initialState: ProjectListState = {
    list: [],
}

export const fetchProjectListAsync = createAsyncThunk('projects/fetchProjectList', async (): Promise<Project[]> => {
    const response = await projectListApi()
    return response.data
})

export const deleteProjectAsync = createAsyncThunk('projects/deleteProject', async (projectId: string) => {
    const response = await deleteProjectApi(projectId)
    return {id: projectId, response: response.data}
})

export const projectListSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectListAsync.fulfilled, (state, action) => {
                state.list = action.payload
            })
            .addCase(deleteProjectAsync.fulfilled, (state, action) => {
                state.list = state.list.filter((project) => project.id !== action.payload.id)
                message.info('Project has been deleted.')
            })
    },
})

export default projectListSlice.reducer
