import React from 'react'
import {CloseOutlined} from '@ant-design/icons'
import './Tiles.css'
import {Button} from 'antd'

interface Props {
    n: string
    content?: string
    closeButtonCallback?: () => void
}

// https://loading.io/color/feature/Spectral-10/
const COLOR_PALETTE = new Map<number, string>([
    [0, '#9e0142'],
    [1, '#d53e4f'],
    [2, '#f46d43'],
    [3, '#fdae61'],
    [4, '#fee08b'],
    [5, '#e6f598'],
    [6, '#abdda4'],
    [7, '#66c2a5'],
    [8, '#3288bd'],
    [9, '#5e4fa2'],
    [NaN, '#9e0142'],
])

const Tiles: React.FC<Props> = (props) => (
    <div style={{position: 'relative'}}>
        <div
            style={{
                backgroundColor: COLOR_PALETTE.get(trimRightZerosAndConvert(props.n) % 10),
            }}
            className='code-number'
        >
            {props.n}
        </div>
        <div className='code-content'>{props.content}</div>
        {props.closeButtonCallback && (
            <Button
                type='primary'
                shape='circle'
                icon={<CloseOutlined style={{color: 'red'}} />}
                danger
                size='small'
                className='tile-close-button'
                onClick={() => props.closeButtonCallback && props.closeButtonCallback()}
            />
        )}
    </div>
)

function trimRightZerosAndConvert(str: string): number {
    // Remove all zeros from the right side of the string
    const trimmedStr = str.replace(/0+$/, '')

    // Convert the resulting string to a number
    // Return the result
    return Number(trimmedStr)
}

export default Tiles
