import React, {useEffect, useState} from 'react'
import {Steps, Typography} from 'antd'
import {useSelector, useDispatch} from 'react-redux'
import {RootState, AppDispatch} from '../../app/store'
import ProjectCreate from './ProjectCreate'
import UploadData from '../uploadData/UploadData'
import ColumnRecogniseTable from '../columnRecognise/ColumnRecognise'
import {Project, ProjectStatus} from '../../api'
import {useParams} from 'react-router-dom'
import {getProjectByIdAsync, restartCreateProcess} from './projectSlice'
import ReferenceUpload from '../referenceUpload/ReferenceUpload'
import './ProjectCreateProcess.css'
import ParameterSliders from '../SetParameter/SetParameter'
import MachineCoding from '../MachingCoding/MachineCoding'
import CodeBook from '../codebook/CodeBook'
import {ManualCoding} from '../manualCoding/ManualCoding'

const {Title} = Typography
const {Step} = Steps

export const ProjectCreateProcess: React.FC = () => {
    const {projectId} = useParams<{projectId: string}>()

    const currentProject = useSelector((state: RootState) => state.project.project)
    const initStep = useSelector((state: RootState) => state.project.createStep)
    const [stepOverride, setStepOverride] = useState<number | undefined>(initStep)
    const currentStop = mapProjectStatusToStep(currentProject, stepOverride)
    const [, setUserClickedStep] = useState(mapProjectStatusToStep(currentProject, stepOverride))
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        if (projectId) {
            dispatch(getProjectByIdAsync(projectId ? projectId : ''))
        } else {
            dispatch(restartCreateProcess())
        }
    }, [dispatch])
    const onChange = (current: number) => {
        setStepOverride(undefined)
        setUserClickedStep(current)
    }

    const onJumpClicked = (targetStep: number) => {
        console.log('targetStep', targetStep)
        // setStepOverride(targetStep)
    }

    return (
        <div className='create-process'>
            <Title level={2}>{currentProject?.name ? currentProject?.name : 'Create Project'}</Title>
            <Steps current={currentStop} onChange={onChange} className='create-process-steps'>
                <Step title='Project Setting' onClick={() => onJumpClicked(0)} />
                <Step title='Upload Data File' onClick={() => onJumpClicked(1)} />
                <Step title='Recognize Column' onClick={() => onJumpClicked(2)} />
                <Step title='Upload Code Book' onClick={() => onJumpClicked(3)} />
                <Step title='Set Parameter' onClick={() => onJumpClicked(4)} />
                <Step title='Submit To Maching Coding' onClick={() => onJumpClicked(5)} />
                <Step title='CodeBook' onClick={() => onJumpClicked(6)} />
            </Steps>
            {currentStop === 0 && <ProjectCreate />}
            {currentStop === 1 && <UploadData />}
            {currentStop === 2 && <ColumnRecogniseTable />}
            {currentStop === 3 && <ReferenceUpload />}
            {currentStop === 4 && <ParameterSliders />}
            {currentStop === 5 && <MachineCoding />}
            {currentStop === 6 && <ManualCoding />}
        </div>
    )
}

const mapProjectStatusToStep = (project: Project | undefined, stepOverride: number | undefined): number => {
    if (!project) {
        return 0
    }
    if (stepOverride) {
        return stepOverride
    }
    switch (project.status.code) {
        case ProjectStatus.Initiated:
            return 1
        case ProjectStatus.ColumnsRecognised:
            return 2
        case ProjectStatus.ColumnsConfirmed:
            return 3
        case ProjectStatus.AutocodingInProgress:
            return 5
        case ProjectStatus.ReferenceUploaded:
            return 4
        case ProjectStatus.AutocodingCompleted:
            return 6
        default:
            return 4
    }
}
