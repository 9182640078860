// 1. Import the required components and dependencies
import React, {FC, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store' // Import the RootState type from your Redux store
import {Descriptions, Typography, Tag} from 'antd'
import {useAppDispatch} from '../../app/hooks'
import {getProjectByIdAsync} from '../projectCreate/projectSlice'
import {useParams} from 'react-router-dom'

const {Title} = Typography

// 2. Create a project detail component
const ProjectDetail: FC = () => {
    // Replace this with the actual project ID from the URL or other sources
    const {projectId} = useParams<{projectId: string}>()

    // 4. Connect the component to the Redux store
    const project = useSelector((state: RootState) => state.project.project)
    const dispatch = useAppDispatch()

    // 2. Use the useEffect hook to run side effects after the component mounts
    useEffect(() => {
        // 4. Dispatch the action inside the useEffect hook
        dispatch(getProjectByIdAsync(projectId ? projectId : ''))
    }, [dispatch])

    if (!project) {
        return <div>Project not found</div>
    }

    // 3. Use Ant Design components to display the project details
    return (
        <div>
            <Title level={2}>{project.name}</Title>
            <Descriptions bordered column={1}>
                <Descriptions.Item label='Project Type'>{project.projectType}</Descriptions.Item>
                <Descriptions.Item label='Language'>{project.language}</Descriptions.Item>
                <Descriptions.Item label='Status'>{project.status.code}</Descriptions.Item>
                <Descriptions.Item label='Create Time'>
                    {new Date(project.createdTime * 1000).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label='Last Modified Time'>
                    {project.lastModifiedTime ? new Date(project.lastModifiedTime * 1000).toLocaleString() : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label='Tags'>
                    {project.tags ? project.tags.map((tag) => <Tag key={tag}>{tag}</Tag>) : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label='Minimal Count'>{project.minimalCount || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label='Similarity Level'>{project.similarityLevel || 'N/A'}</Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default ProjectDetail
