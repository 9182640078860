import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userSlice from '../features/user/userSlice';
import projectSlice from '../features/projectCreate/projectSlice';
import projectListSlice from '../features/projectList/projectsSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    project: projectSlice,
    projects: projectListSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
