import React, {useEffect} from 'react'
import {Layout} from 'antd'
import {useNavigate, RouterProvider, createBrowserRouter, Outlet} from 'react-router-dom'
import {loginSync} from './features/user/userSlice'
import {useAppDispatch} from './app/hooks'
import {TopHeader} from './components/header/Header'
import ProjectCreate from './features/projectCreate/ProjectCreate'
import FileUpload from './features/uploadData/UploadData'
import ColumnRecognise from './features/columnRecognise/ColumnRecognise'
import {ProjectListTable} from './features/projectList/ProjectList'
import ProjectDetail from './features/projectDetail/ProjectDetails'
import {ProjectCreateProcess} from './features/projectCreate/ProjectCreateProgress'
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner'
import './App.css'
import {useSelector} from 'react-redux'
import {RootState} from './app/store'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import autoCodingImg from './assets/autocoding.png'
import {ManualCoding} from './features/manualCoding/ManualCoding'

const LoginPage: React.FC = () => {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (window.Notification && window.Notification.permission !== 'granted') {
            window.Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.')
                } else {
                    console.log('Unable to get permission to notify.')
                }
            })
        }
        if (user.isAuthenticated) {
            return
        }
        autoLogin()
    }, [])

    function autoLogin() {
        dispatch(loginSync(dispatch))

        // setTimeout(() => {
        //     navigate('/projects')
        // }, 1500)
    }

    return (
        <div className='middle'>
            <div className='login-success'>
                <img src={autoCodingImg} alt='login success' className='default-root-img' />
            </div>
        </div>
    )
}

const {Footer} = Layout

const App: React.FC = () => {
    return (
        <div>
            <Layout>
                <RouterProvider router={route} />
            </Layout>
            <LoadingSpinner />
        </div>
    )
}

const route = createBrowserRouter([
    {
        path: '/',
        element: (
            <div id='rootRouter'>
                <TopHeader />
                <Outlet />
                <Footer />
            </div>
        ),
        children: [
            {
                path: '/',
                element: <LoginPage />,
            },
            {
                path: 'projects',
                element: <ProjectListTable />,
            },
            {
                path: 'create',
                element: <ProjectCreate />,
            },
            {
                path: 'login',
                element: <LoginPage />,
            },
            {
                path: 'upload',
                element: <FileUpload />,
            },
            {
                path: 'columnRecognise',
                element: <ColumnRecognise />,
            },
            {
                path: 'project/:projectId',
                element: <ProjectDetail />,
            },
            {
                path: 'createProcess/:projectId',
                element: <ProjectCreateProcess />,
            },
            {
                path: 'createProcess',
                element: <ProjectCreateProcess />,
            },
            {
                path: 'manualCoding',
                element: <ManualCoding />,
            },
        ],
    },
])

export default App
