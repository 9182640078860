import {getProjectApi} from '../projectDetail/projectApi'
import {delay, putFileToS3PresignedUrl} from '../../utils/commonUtils'
import axios, {AxiosResponse} from 'axios'
import {API_BASE_URL} from '../../constant'
import {getAuthHeader} from '../../utils/apiClient'
import {Project, ProjectStatus, FileExchangeUrl} from '../../api'

export async function uploadDataApi(projectId: string, file: File) {
    const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/data-file/upload-url`, getAuthHeader())
    const data: FileExchangeUrl = response.data
    const url = data.url
    await putFileToS3PresignedUrl(file, url)
    return pollProjectStatus(projectId)
}

async function pollProjectStatus(projectId: string): Promise<AxiosResponse<any>> {
    const projectResponse: AxiosResponse<any> = await getProjectApi(projectId)
    const project: Project = projectResponse.data
    console.log(project.status.code)
    console.log('target', ProjectStatus.ColumnsRecognised)
    if (
        project.status.code === ProjectStatus.ColumnsRecognised &&
        project.columns?.length &&
        project.columns?.length > 0
    ) {
        return projectResponse
    } else {
        await delay(2000)
        return pollProjectStatus(projectId)
    }
}
