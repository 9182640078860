// 1. Import the required components
import React, {FC} from 'react'
import {Button, Checkbox, Select, Table} from 'antd'
import {ColumnsType} from 'antd/lib/table'
import {ColumnRecognizeResult, ColumnType, Project} from '../../api'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import {getColumnType} from '../../utils/commonUtils'
import {putColumnsAsync, putProject} from '../projectCreate/projectSlice'
import {useAppDispatch} from '../../app/hooks'

// 2. Define the data structure for the table
const {Option} = Select

// Define the ColumnType enum
// 2. Define the data structure for the table
interface TableRow {
    key: string
    name: string
    label: string
    type: ColumnType
    analysis: boolean
}

// 3. Create a table component
const ColumnRecogniseTable: FC = () => {
    // Sample data for the table
    const project = useSelector((state: RootState) => state.project).project
    let key = 0
    const tableData = project?.columns
        ? project?.columns?.map((c: ColumnRecognizeResult) => ({
              key: `${key++}`,
              name: c.name,
              label: c.name,
              type: c.columnType ? c.columnType : ColumnType.Other,
              analysis: c.analysis ? c.analysis : false,
          }))
        : []

    // 4. Add the Table component from Ant Design
    // 5. Configure the columns and data for the table
    // 6. Update the third column to use a dropdown box with the ColumnType enum values
    const dispatch = useAppDispatch()

    const handleSelectChange = (index: number, value: string) => {
        if (!project || !project.columns) {
            return
        }
        const newColumns: ColumnRecognizeResult[] = [...project.columns]
        newColumns[index] = {...newColumns[index], columnType: getColumnType(value)}
        const newProject: Project = {...project}
        newProject.columns = newColumns
        dispatch(putProject(newProject))
    }

    const handleCheckChange = (index: number, value: boolean) => {
        if (!project || !project.columns) {
            return
        }
        const newColumns: ColumnRecognizeResult[] = [...project.columns]
        newColumns[index] = {...newColumns[index], analysis: !value}
        const newProject: Project = {...project}
        newProject.columns = newColumns
        dispatch(putProject(newProject))
    }
    const columns: ColumnsType<TableRow> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: ColumnType, record: TableRow, index: number) => (
                <Select value={type} style={{width: '100%'}} onChange={(value) => handleSelectChange(index, value)}>
                    {Object.entries(ColumnType).map(([key, value]) => (
                        <Option key={key} value={value}>
                            {value.toUpperCase()}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Analysis',
            dataIndex: 'analysis',
            key: 'analysis',
            render: (checked: boolean, record: TableRow, index: number) => (
                <Checkbox checked={checked} onChange={() => handleCheckChange(index, checked)} />
            ),
        },
    ]
    const onSubmitClick = () => {
        if (!project || !project.columns) {
            return
        }
        dispatch(
            putColumnsAsync({
                projectId: project.id,
                payload: project.columns,
            }),
        )
    }
    return (
        <div>
            <h1>Table View</h1>
            <Table<TableRow> columns={columns} dataSource={tableData} />
            <Button type='primary' onClick={() => onSubmitClick()}>
                Confirm Column Recognize
            </Button>
        </div>
    )
}

export default ColumnRecogniseTable
