import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {message} from 'antd'
import {
    Answer,
    AutocodingResultInner,
    Codebook,
    CodebookRequest,
    ColumnRecognizeResult,
    Project,
    ProjectRequestBody,
    ProjectStatus,
    Question,
} from '../../api'
import {createProjectApi, putProjectApi, upsertProjectQuestionsApi} from './projectApi'
import {uploadDataApi} from '../uploadData/uploadDataApi'
import {getProjectApi} from '../projectDetail/projectApi'
import {putColumnRecognize} from '../columnRecognise/ColumnRecogniseApi'
import {confirmReferenceData, uploadCodeBookApi, uploadDatabaseApi} from '../referenceUpload/ReferenceUploadApi'
import {updateProjectSettingApi} from '../SetParameter/SetParameterApi'
import {triggerMachineCodingApi} from '../MachingCoding/machineCodingApi'
import {getCodeBookApi, reUploadReferenceApi, updateCodeBookApi} from '../codebook/CodeBookApi'

export interface ProjectState {
    project?: Project
    codeBook?: Codebook
    createStep?: number
    isLoading: boolean
    locallyUpdated: boolean
}

const initialState: ProjectState = {
    isLoading: false,
    locallyUpdated: false,
}

export type PutProjectRequestBody = ProjectRequestBody & {id: string}

export const createProjectAsync = createAsyncThunk(
    'project/putProject',
    async (projectRequest: ProjectRequestBody): Promise<Project> => {
        const request: ProjectRequestBody = {
            ...projectRequest,
            tags: [],
            minimalCount: undefined,
            similarityLevel: undefined,
        }
        const response = await createProjectApi(request)
        return response.data
    },
)

export const putProjectAsync = createAsyncThunk(
    'project/putProject',
    async (options: {projectId: string; payload: PutProjectRequestBody}): Promise<Project> => {
        const response = await putProjectApi(options.projectId, options.payload)
        return response.data
    },
)

export const upsertProjectQuestionAsync = createAsyncThunk(
    'project/upsertProjectQuestion',
    async (options: {
        projectId: string
        payload: Question[]
        codebookId: string | undefined
        modelGeneratedCodebook: Codebook | undefined
    }): Promise<any> => {
        console.log(options.payload)
        const projectResponse = upsertProjectQuestionsApi(options.projectId, options.payload)
        const codebookRequest: CodebookRequest = {
            name: options.modelGeneratedCodebook?.name,
            codes: options.modelGeneratedCodebook?.codes,
        }
        const codebookResponse = updateCodeBookApi(options.codebookId, codebookRequest)
        return Promise.all([projectResponse, codebookResponse])
    },
)

export const putColumnsAsync = createAsyncThunk(
    'project/putColumns',
    async (options: {projectId: string; payload: ColumnRecognizeResult[]}): Promise<Project> => {
        const response = await putColumnRecognize(options.projectId, options.payload)
        return response.data
    },
)

export const uploadDataFileAsync = createAsyncThunk(
    'project/uploadDataFile',
    async (options: {projectId: string; file: File}): Promise<Project> => {
        const response = await uploadDataApi(options.projectId, options.file)
        return response.data
    },
)

export const uploadCodeBookAsync = createAsyncThunk(
    'project/uploadCodeBook',
    async (options: {projectId: string; file: File}): Promise<Project> => {
        const response = await uploadCodeBookApi(options.projectId, options.file)
        return response.data
    },
)

export const uploadDatabaseAsync = createAsyncThunk(
    'project/uploadDatabase',
    async (options: {projectId: string; file: File}): Promise<Project> => {
        const response = await uploadDatabaseApi(options.projectId, options.file)
        return response.data
    },
)

export const confirmReferenceDataAsync = createAsyncThunk(
    'project/confirmReferenceData',
    async (projectId: string): Promise<Project> => {
        await confirmReferenceData(projectId)
        const response = await getProjectApi(projectId)
        return response.data
    },
)

export const getProjectByIdAsync = createAsyncThunk('project/getById', async (projectId: string): Promise<Project> => {
    const response = await getProjectApi(projectId)
    return response.data
})

export const updateProjectAsync = createAsyncThunk(
    'project/update',
    async (projectRequest: PutProjectRequestBody): Promise<Project> => {
        const response = await updateProjectSettingApi(projectRequest)
        await triggerMachineCodingApi(projectRequest.id)
        return response.data
    },
)

export const triggerProjectAsync = createAsyncThunk('project/trigger', async (projectId: string): Promise<Project> => {
    const response = await triggerMachineCodingApi(projectId)
    return response.data
})

export const getCodebookAsync = createAsyncThunk(
    'project/getCodebook',
    async (projectId: string): Promise<AutocodingResultInner[]> => {
        return await getCodeBookApi(projectId)
    },
)

export const projectCreateNavigate = createAsyncThunk('project/navigate', (navigateToStep: number): Promise<number> => {
    return Promise.resolve(navigateToStep)
})

export const reUploadReferenceDataAsync = createAsyncThunk(
    'project/reUploadReferenceData',
    async (projectId: string): Promise<Project> => {
        await reUploadReferenceApi(projectId)
        const response = await getProjectApi(projectId)
        return response.data
    },
)

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        putProject: (state, data) => {
            state.project = {...data.payload}
        },
        projectNavigate: (state, data) => {
            console.log(data.payload)
            state.createStep = data.payload
        },
        submit: (state) => {
            if (!state.project) {
                return
            }
            state.project = {
                ...state.project,
                status: {...state.project?.status, code: ProjectStatus.AutocodingInProgress},
            }
        },
        restartCreateProcess: (state) => {
            state.project = undefined
        },
        updateCodebook: (state, data) => {
            const {newCodes, keep, remove} = data.payload
            if (state.project && state.project.questions && state.project.questions[0].answers) {
                const newAnswers: Answer[] = state.project.questions[0].answers.map((answer) => ({
                    ...answer,
                    linkedCodes: answer.linkedCodes?.map((code) => (remove.includes(code) ? keep : code)),
                }))
                state.project = {
                    ...state.project,
                    id: state.project.id,
                    questions: [
                        {
                            ...state.project.questions[0],
                            answers: newAnswers,
                            modelGeneratedCodebook: newCodes,
                        },
                    ],
                }
                state.codeBook = {
                    ...state.codeBook,
                    codes: newCodes,
                }
                state.locallyUpdated = true
            }
        },
        updateQuestion: (state, data) => {
            if (state.project && state.project.questions && state.project.questions[0]) {
                state.project = {
                    ...state.project,
                    id: state.project.id,
                    questions: [data.payload],
                }
                state.locallyUpdated = true
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createProjectAsync.fulfilled, (state, action) => {
                state.project = action.payload
            })
            .addCase(createProjectAsync.rejected, (state, action) => {
                console.log(action)
            })
            .addCase(getProjectByIdAsync.pending, (state, action) => {
                state.project = undefined
                state.isLoading = true
                state.codeBook = undefined
                state.locallyUpdated = false
            })
            .addCase(getProjectByIdAsync.fulfilled, (state, action) => {
                state.project = action.payload
                if (action.payload.questions) {
                    state.isLoading = false
                    state.codeBook = action.payload.questions[0].modelGeneratedCodebook
                }
            })
            .addCase(uploadDataFileAsync.fulfilled, (state, action) => {
                state.project = action.payload
                state.isLoading = false
            })
            .addCase(uploadDataFileAsync.pending, (state) => {
                state.isLoading = true
            })
            .addCase(uploadDatabaseAsync.fulfilled, (state, action) => {
                state.project = action.payload
                state.isLoading = false
            })
            .addCase(uploadDatabaseAsync.pending, (state) => {
                state.isLoading = true
            })
            .addCase(uploadCodeBookAsync.pending, (state) => {
                state.isLoading = true
            })
            .addCase(uploadCodeBookAsync.fulfilled, (state, action) => {
                state.isLoading = false
                state.project = action.payload
            })
            .addCase(putColumnsAsync.fulfilled, (state, action) => {
                state.project = action.payload
            })
            .addCase(updateProjectAsync.fulfilled, (state, action) => {
                state.project = {...action.payload, status: {success: true, code: ProjectStatus.AutocodingInProgress}}
            })
            .addCase(triggerProjectAsync.fulfilled, (state, action) => {
                state.project = action.payload
            })
            .addCase(getCodebookAsync.fulfilled, (state, action) => {
                // state.codeBook = action.payload
            })
            .addCase(confirmReferenceDataAsync.fulfilled, (state, action) => {
                state.project = action.payload
            })
            .addCase(reUploadReferenceDataAsync.fulfilled, (state, action) => {
                state.project = action.payload
            })
            .addCase(upsertProjectQuestionAsync.fulfilled, (state, action) => {
                state.locallyUpdated = false
                message.success('Local changes have been persisted to server', 5)
            })
            .addCase(upsertProjectQuestionAsync.rejected, (state) => {
                state.locallyUpdated = false
                message.error('Changes failed to save, please try again later', 5)
            })
    },
})

export const {putProject, updateQuestion, submit, updateCodebook, restartCreateProcess, projectNavigate} =
    projectSlice.actions

export default projectSlice.reducer
