/* tslint:disable */
/* eslint-disable */
/**
 * Parallels Data
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ColumnType = {
    Numeric: 'NUMERIC',
    Text: 'TEXT',
    Date: 'DATE',
    Other: 'OTHER',
} as const
export type ColumnType = (typeof ColumnType)[keyof typeof ColumnType]

export function ColumnTypeFromJSON(json: any): ColumnType {
    return ColumnTypeFromJSONTyped(json, false)
}

export function ColumnTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnType {
    return json as ColumnType
}

export function ColumnTypeToJSON(value?: ColumnType | null): any {
    return value as any
}
