import React, {useRef, useState} from 'react'
import {Input, Checkbox, Button, Modal, Divider} from 'antd'
import {Answer, Question, Codebook} from '../../api'
import {FixedSizeList as List} from 'react-window'

import Tiles from '../../components/Tiles/Tiles'
import './Answer.css'
import {
    SearchOutlined,
    EditOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    StarOutlined,
    StarFilled,
    CloseOutlined,
} from '@ant-design/icons'
import {CheckboxChangeEvent} from 'antd/es/checkbox'
import {useAppDispatch} from '../../app/hooks'
import {updateQuestion} from '../projectCreate/projectSlice'
import CodebookUI from './Codebook'
import {nextState} from '../../utils/commonUtils'

interface AnswersViewProps {
    selectedQuestion?: Question
    codebook?: Codebook
}

interface SelectedAnswers {
    isChecked: boolean
    answer: Answer
}

const AnswersView: React.FC<AnswersViewProps> = (props: AnswersViewProps) => {
    const dispatch = useAppDispatch()

    const [checked, setChecked] = useState<{[key: string]: SelectedAnswers}>({})
    const [filter, setFilter] = useState<{content: string; code: string; marked: undefined | boolean}>({
        content: '',
        code: '',
        marked: undefined,
    })
    const [indexesUpdating, setIndexesUpdating] = useState<number[]>([])
    const [codebookChecked, setCodebookChecked] = useState<{[key: string]: boolean}>({})
    const [sortOption, setSortOption] = useState<{byContent: boolean | undefined; byCode: boolean | undefined}>({
        byContent: true,
        byCode: true,
    })
    const modalContentRef = useRef<HTMLDivElement>(null)
    const actionHeaderRef = useRef<HTMLDivElement>(null)

    const toggleChecked = (answer: Answer) => {
        if (answer.id) {
            const id: string = answer.id
            setChecked((prevState) => ({
                ...prevState,
                [id]: {
                    isChecked: !prevState[id]?.isChecked,
                    answer,
                },
            }))
        }
    }

    const onChange = (e: CheckboxChangeEvent, answer: Answer) => {
        toggleChecked(answer)
    }
    const answers: Answer[] = props.selectedQuestion?.answers ?? []

    const handleContentFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({content: e.target.value, code: filter.code, marked: filter.marked})
    }

    const handleCodeFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({content: filter.content, code: e.target.value, marked: filter.marked})
    }

    const handleMarkedFilterChange = () => {
        setFilter({content: filter.content, code: filter.code, marked: nextState(filter.marked)})
    }

    const filteredCodes = answers
        .filter((item: Answer) => {
            if (item.id && checked[item.id]) {
                return true
            }
            if (item.content) {
                return item.content.toLowerCase().includes(filter.content.toLowerCase())
            } else {
                return false
            }
        })
        .filter((item: Answer) => {
            if (item.id && checked[item.id]) {
                return true
            }
            if (item.linkedCodes) {
                return item.linkedCodes.some((code) => {
                    return code.toLowerCase().includes(filter.code.toLowerCase())
                })
            } else {
                return false
            }
        })
        .filter((item: Answer) => {
            if (item.id && checked[item.id]) {
                return true
            }
            if (filter.marked === undefined) {
                return true
            }
            if (item.marked !== undefined) {
                return item.marked === filter.marked
            }
            return false
        })
        .sort((a: Answer, b: Answer) => {
            if (sortOption.byContent !== undefined && sortOption.byCode !== undefined) {
                return sortAnswersByCode(a, b, sortOption.byCode, sortOption.byContent)
            } else if (sortOption.byCode === undefined) {
                return sortAnswersByName(a, b, sortOption.byContent)
            } else if (sortOption.byContent === undefined) {
                return sortAnswersByCode(a, b, sortOption.byCode)
            } else {
                return 0
            }
        })

    const showModal = (answerIndex: number) => {
        setIndexesUpdating([answerIndex])
    }

    const handleOk = () => {
        const newAnswers: Map<string, Answer> = new Map<string, Answer>()
        indexesUpdating.forEach((index) => {
            if (filteredCodes[index].id != null) {
                newAnswers.set(filteredCodes[index].id ?? '', {
                    ...filteredCodes[index],
                    linkedCodes: Object.keys(codebookChecked).filter((key) => codebookChecked[key]),
                })
            }
        })
        const newAnswerArray = answers.map((answer: Answer) =>
            answer.id != null && newAnswers.get(answer.id) != null ? newAnswers.get(answer.id) : answer,
        )

        dispatch(
            updateQuestion({
                ...props.selectedQuestion,
                answers: newAnswerArray,
            }),
        )
        setIndexesUpdating([])
        setChecked({})
    }

    const handleCancel = () => {
        setIndexesUpdating([])
    }

    const onCodeBookChange = (selectedCode: {[p: string]: boolean}) => {
        setCodebookChecked(selectedCode)
    }

    const handleAnswerEditClick = () => {
        // Add your edit logic here
    }

    const onMergeClick = () => {
        const checkedAnswers: Answer[] = []
        const uncheckedAnswers: Answer[] = []
        answers.forEach((answer: Answer, index: number) => {
            if (answer.id && checked[answer.id]) {
                checkedAnswers.push(answer)
            } else {
                uncheckedAnswers.push(answer)
            }
        })
        const newLinkedCodes = checkedAnswers
            .map((item: Answer) => item.linkedCodes)
            .reduce((acc: string[], val: string[] | undefined) => acc.concat(val || []), [] as string[])
            .filter((item): item is string => item !== undefined)
        const mergedAnswer: Answer = {
            ...checkedAnswers[0],
            childrenAnswers: checkedAnswers
                .slice(-(checkedAnswers.length - 1))
                .map((a: Answer) => {
                    if (a.content && a.childrenAnswers) {
                        const na = [...a.childrenAnswers]
                        na.push(a.content)
                        return na
                    }
                })
                .reduce((acc: string[], val: string[] | undefined) => acc.concat(val || []), [] as string[])
                .filter((item): item is string => item !== undefined),
            linkedCodes: [...new Set(newLinkedCodes)],
        }
        uncheckedAnswers.push(mergedAnswer)
        dispatch(
            updateQuestion({
                ...props.selectedQuestion,
                answers: uncheckedAnswers,
            }),
        )
        if (mergedAnswer.id) {
            setChecked({
                [mergedAnswer.id]: {
                    isChecked: true,
                    answer: mergedAnswer,
                },
            })
        }
    }

    const onBulkEditClick = () => {
        const checkedAnswers: Answer[] = []
        answers.forEach((answer: Answer, index: number) => {
            if (answer.id && checked[answer.id]) {
                checkedAnswers.push(answer)
            }
        })
        setIndexesUpdating(checkedAnswers.map((a) => filteredCodes.indexOf(a)))
    }

    const onCodeMerge = (keep: string, remove: string[]) => {
        const newAnswers: Answer[] = answers.map((answer) => ({
            ...answer,
            linkedCodes: answer.linkedCodes?.map((code) => (remove.includes(code) ? keep : code)),
        }))
    }

    const markAnswer = (index: number) => {
        const answerChosen = filteredCodes[index]
        const newAnswers: Map<string, Answer> = new Map<string, Answer>()
        newAnswers.set(answerChosen.id ?? '', {
            ...answerChosen,
            marked: !answerChosen.marked,
        })
        const newAnswerArray = answers.map((answer: Answer) =>
            answer.id != null && newAnswers.get(answer.id) != null ? newAnswers.get(answer.id) : answer,
        )

        dispatch(
            updateQuestion({
                ...props.selectedQuestion,
                answers: newAnswerArray,
            }),
        )
    }

    const Row = ({index, style}: {index: number; style: React.CSSProperties}) => {
        const item = filteredCodes[index]
        const code = item.id
        if (!code) {
            return <div></div>
        }
        return (
            <div className='codebook-item answers' style={style}>
                <div className='codebook-item-left'>
                    <Checkbox
                        className='codebook-item-check-box'
                        checked={checked[code]?.isChecked || false}
                        onChange={(e) => onChange(e, item)}
                    ></Checkbox>
                    <CodeTiles linkedCode={item.linkedCodes ?? []} />
                    <label className='codebook-item-name'>
                        {item.content}
                        {item.childrenAnswers && item.childrenAnswers.length > 0 ? ',' : ''}
                        {item.childrenAnswers?.join(',')}
                    </label>
                </div>
                <div>
                    <Button
                        icon={item.marked ? <StarFilled className='answer-marked-icon' /> : <StarOutlined />}
                        onClick={() => markAnswer(index)}
                    />
                    <Button icon={<EditOutlined />} onClick={() => showModal(index)} />
                </div>
            </div>
        )
    }
    return (
        <>
            <h2>Answers</h2>

            <Divider />
            <div ref={actionHeaderRef}>
                <h3>Bulk Actions</h3>
                <div className='answer-buttons'>
                    <Button
                        type='primary'
                        className='answer-button answer-button-edit'
                        onClick={onBulkEditClick}
                        disabled={Object.values(checked).filter((value) => value).length < 2}
                    >
                        Edit
                    </Button>

                    <Button
                        type='primary'
                        className='answer-button answer-button-uncheck'
                        onClick={() => setChecked({})}
                        disabled={Object.values(checked).filter((value) => value).length === 0}
                    >
                        Uncheck All
                    </Button>
                    <Button
                        type={sortOption.byCode === undefined ? 'default' : 'primary'}
                        className='answer-button answer-button-sort'
                        onClick={() =>
                            setSortOption({
                                byCode: nextState(sortOption.byCode),
                                byContent: sortOption.byContent,
                            })
                        }
                    >
                        Sort by Code{' '}
                        {sortOption.byCode !== undefined ? (
                            sortOption.byCode ? (
                                <ArrowUpOutlined />
                            ) : (
                                <ArrowDownOutlined />
                            )
                        ) : (
                            <div></div>
                        )}
                    </Button>

                    <Button
                        type={sortOption.byContent === undefined ? 'default' : 'primary'}
                        className='answer-button answer-button-sort'
                        onClick={() =>
                            setSortOption({
                                byCode: sortOption.byCode,
                                byContent: nextState(sortOption.byContent),
                            })
                        }
                    >
                        Sort by Name{' '}
                        {sortOption.byContent !== undefined ? (
                            sortOption.byContent ? (
                                <ArrowUpOutlined />
                            ) : (
                                <ArrowDownOutlined />
                            )
                        ) : (
                            <div></div>
                        )}
                    </Button>
                </div>
                <Divider />
                <div className='answer-filter-section'>
                    <div className='answer-filter-item'>
                        <Input
                            placeholder='Code Filter'
                            value={filter.code}
                            onChange={handleCodeFilterChange}
                            prefix={<SearchOutlined />}
                            className='answer-filter-input'
                        />
                    </div>

                    <div className='answer-filter-item'>
                        <Input
                            placeholder='Content Filter'
                            value={filter.content}
                            onChange={handleContentFilterChange}
                            prefix={<SearchOutlined />}
                            className='answer-filter-input'
                        />
                    </div>
                    <Button
                        className={'mark-button-filter answer-button-filter-mark-' + filter.marked?.toString()}
                        icon={
                            filter.marked !== undefined ? (
                                <StarFilled className={'answer-marked-icon-' + filter.marked?.toString()} />
                            ) : (
                                <StarOutlined className={'answer-marked-icon-undefined'} />
                            )
                        }
                        onClick={() => handleMarkedFilterChange()}
                    />
                </div>
                <Divider />
                <div className='selected-answer'>
                    Selected Answers:
                    <div className='checked-tiles-row'>
                        {Object.entries(checked).map(([key, value]) => {
                            if (value.isChecked) {
                                return (
                                    <div key={key} className='checked-tiles'>
                                        <CodeTiles
                                            linkedCode={value.answer.linkedCodes ?? []}
                                            key={key}
                                            closeButtonCallback={() => toggleChecked(value.answer)}
                                        />
                                        <div className='checked-tiles-content'>{value.answer.content}</div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
                <Divider />
            </div>
            <List height={500} width={700} itemCount={filteredCodes.length} itemSize={35}>
                {Row}
            </List>
            <Modal
                title='Code selection'
                visible={indexesUpdating.length > 0}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                className='codebook-modal'
            >
                <div ref={modalContentRef}>
                    <CodebookUI
                        codebook={props.codebook}
                        preTickedCode={flattenedCodes(indexesUpdating, filteredCodes)}
                        onSelectUpdate={onCodeBookChange}
                        inModal={true}
                        modelTitle={filteredCodes
                            .filter((_, index) => indexesUpdating.includes(index))
                            .map((a: Answer) => a.content)
                            .join(', ')}
                        onConfirm={handleOk}
                        modelRef={modalContentRef}
                    />
                </div>
            </Modal>
        </>
    )
}
interface CodeTilesProps {
    linkedCode: string[]
    closeButtonCallback?: () => void
}

const CodeTiles: React.FC<CodeTilesProps> = ({linkedCode, closeButtonCallback}) => {
    return (
        <div className='answer-tile-list'>
            {linkedCode.map((item) => (
                <Tiles n={item} key={item} />
            ))}
            {closeButtonCallback && (
                <Button
                    type='primary'
                    shape='circle'
                    icon={<CloseOutlined style={{color: 'red'}} />}
                    danger
                    size='small'
                    className='tiles-close-button'
                    onClick={() => closeButtonCallback && closeButtonCallback()}
                />
            )}
        </div>
    )
}

const sortAnswersByCode = (a: Answer, b: Answer, ascendByCode?: boolean, ascendByName?: boolean): number => {
    const firstLinkedCodeA = a.linkedCodes ? a.linkedCodes[0] : ''
    const firstLinkedCodeB = b.linkedCodes ? b.linkedCodes[0] : ''

    if (Number(firstLinkedCodeA) < Number(firstLinkedCodeB)) {
        return ascendByCode ? -1 : 1
    }
    if (Number(firstLinkedCodeA) > Number(firstLinkedCodeB)) {
        return ascendByCode ? 1 : -1
    }
    if (ascendByName !== undefined) {
        return sortAnswersByName(a, b, ascendByName)
    }
    return 0
}
const sortAnswersByName = (a: Answer, b: Answer, ascending?: boolean): number => {
    const firstContentA = a.content ? a.content.toLowerCase() : ''
    const firstContentB = b.content ? b.content.toLowerCase() : ''

    if (firstContentA < firstContentB) {
        return ascending ? -1 : 1
    }
    if (firstContentA > firstContentB) {
        return ascending ? 1 : -1
    }
    return 0
}

const flattenedCodes = (indexes: number[], answers: Answer[]): string[] => {
    return indexes.flatMap((index) => answers[index].linkedCodes || [])
}

export default AnswersView
