import axios from 'axios'
import {API_BASE_URL} from '../../constant'
import {getAuthHeader} from '../../utils/apiClient'
import {AutocodingResultInnerFromJSON, CodebookCode, CodebookRequest, FileExchangeUrl} from '../../api'

export async function getCodeBookApi(projectId: string) {
    const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/autocoding/result`, getAuthHeader())
    return response.data.map(AutocodingResultInnerFromJSON)
}

export async function downloadCodeBookApi(projectId: string | undefined) {
    if (!projectId) {
        return
    }
    const response = await axios.get(
        `${API_BASE_URL}/projects/${projectId}/results/codebook/download-url`,
        getAuthHeader(),
    )
    const url: FileExchangeUrl = response.data
    // Create a new 'a' element, click it to download the file
    const link = document.createElement('a')
    link.href = url.url
    link.download = 'filename' // Optional: Set a filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export async function downloadCodeResultApi(projectId: string | undefined) {
    if (!projectId) {
        return
    }
    const response = await axios.get(
        `${API_BASE_URL}/projects/${projectId}/results/code-result/download-url`,
        getAuthHeader(),
    )
    const url: FileExchangeUrl = response.data
    // Create a new 'a' element, click it to download the file
    const link = document.createElement('a')
    link.href = url.url
    link.download = 'filename' // Optional: Set a filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export async function reUploadReferenceApi(projectId: string | undefined) {
    if (!projectId) {
        return
    }
    const response = await axios.post(`${API_BASE_URL}/projects/${projectId}/start-over`, {}, getAuthHeader())
    return response.data
}

export async function updateCodeBookApi(codebookId: string | undefined, codebook: CodebookRequest) {
    console.log(codebookId, codebook, 'updating codebook')
    if (!codebookId) {
        return
    }
    const response = await axios.put(`${API_BASE_URL}/codebooks/${codebookId}`, codebook, getAuthHeader())
    return response.data
}
