import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AppDispatch, RootState, store} from '../../app/store'
import {fetchToken, getTokenFromUrl, getUserDetail} from './userApi'
import {UserData} from '../../api'
import {AUTH_REDIRECT_URI} from '../../constant'

export interface UserState {
    isAuthenticated: boolean
    accessToken?: string
    tokenExpireTime?: number
    isLoading?: boolean
    user?: UserData
}

export interface TokenResponse {
    accessToken: string
    expiresInSeconds: number
}

const initialState: UserState = {isAuthenticated: false}

const COGNITO_URL = `https://auth.paralleldataresearch.net/login?response_type=code&client_id=38pjg8jv2jrh70ijf5m3q79mvd&redirect_uri=${encodeURIComponent(
    AUTH_REDIRECT_URI,
)}`

export const loginSync = createAsyncThunk(
    'user/getToken',
    async (dispatch: typeof store.dispatch): Promise<TokenResponse | undefined> => {
        const token = localStorage.getItem('token')
        const authorisationCode = getTokenFromUrl()
        if (window.location.hostname === 'localhost') {
            dispatch(getUserDetailAsync())
            localStorage.setItem('token', token ?? '')
            return Promise.resolve({
                accessToken: token ?? '',
                expiresInSeconds: 86400,
            })
        }
        if (!token && !authorisationCode) {
            window.location.href = COGNITO_URL
        }
        try {
            const response = await fetchToken()
            localStorage.setItem('token', response.data.accessToken)
            dispatch(getUserDetailAsync())
            return response.data
        } catch (e) {
            localStorage.clear()
            window.location.href = COGNITO_URL
            return Promise.resolve(undefined)
        }
    },
)

export const getUserDetailAsync = createAsyncThunk('user/getUserDetail', async () => {
    const response = await getUserDetail()
    return response.data
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state) => {
            state.isAuthenticated = true
        },
        logout: (state) => {
            state.isAuthenticated = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginSync.fulfilled, (state, action) => {
                if (!action.payload) {
                    return
                }
                state.isAuthenticated = true
                state.accessToken = action.payload.accessToken
                state.tokenExpireTime = getCurrentTimeInSeconds() + action.payload.expiresInSeconds
                state.isLoading = false
            })
            .addCase(loginSync.rejected, (state) => {
                state.isAuthenticated = false
                state.isLoading = false
            })
            .addCase(loginSync.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserDetailAsync.fulfilled, (state, action) => {
                state.isLoading = false
                state.user = action.payload
            })
    },
})
export const selectUser = (state: RootState) => state.user

export const {login} = userSlice.actions

export default userSlice.reducer

function getCurrentTimeInSeconds(): number {
    const currentTime = new Date()
    return Math.floor(currentTime.getTime() / 1000)
}
