import React, {FC, useEffect, useState} from 'react'
import {Table, Checkbox, Button, Input, Modal} from 'antd'
import {ColumnsType} from 'antd/lib/table'
import {Project, ProjectStatus} from '../../api'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import {deleteProjectAsync, fetchProjectListAsync} from './projectsSlice'
import {useAppDispatch} from '../../app/hooks'
import {useNavigate} from 'react-router-dom'
import './ProjectList.css'
import {ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, SearchOutlined} from '@ant-design/icons'
import {nextState} from '../../utils/commonUtils'

interface TableRow {
    key: string
    id: string
    name: string
    status: string
    chosen: boolean
    finished: boolean
    createdTime: number | null | undefined
    lastModifiedTime: number | null | undefined
}

export const ProjectListTable: FC = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [checked, setChecked] = useState<{[key: string]: boolean}>({})
    const [searchValue, setSearchValue] = useState<string>('')
    const toggleChecked = (key: string) => {
        setChecked((prevState) => ({...prevState, [key]: !prevState[key]}))
    }
    const [sortOption, setSortOption] = useState<{
        byName: boolean | undefined
        byLastModified: boolean | undefined
        byCreateTime: boolean | undefined
    }>({
        byLastModified: false,
        byCreateTime: undefined,
        byName: undefined,
    })
    const projects = useSelector((state: RootState) => state.projects)
    let i = 1
    const tableData: TableRow[] = projects.list.map((p: Project) => ({
        key: `${i++}`,
        name: p.name,
        id: p.id,
        status: p.status.code,
        chosen: false,
        finished: p.status.code === ProjectStatus.ReferenceUploaded,
        createdTime: p.createdTime,
        lastModifiedTime: p.lastModifiedTime,
    }))

    const filteredData = tableData.filter((row) => {
        return row.name.toLowerCase().includes(searchValue.toLowerCase())
    })

    const sortedTableData = filteredData.sort((a, b) => {
        if (sortOption.byName !== undefined) {
            return sortOption.byName ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
        }
        if (sortOption.byCreateTime !== undefined) {
            return sortOption.byCreateTime
                ? (a.createdTime || 0) - (b.createdTime || 0)
                : (b.createdTime || 0) - (a.createdTime || 0)
        }
        if (sortOption.byLastModified !== undefined) {
            return sortOption.byLastModified
                ? (a.lastModifiedTime || 0) - (b.lastModifiedTime || 0)
                : (b.lastModifiedTime || 0) - (a.lastModifiedTime || 0)
        }
        return 0
    })

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    // 2. Use the useEffect hook to run side effects after the component mounts
    useEffect(() => {
        // 4. Dispatch the action inside the useEffect hook
        dispatch(fetchProjectListAsync())
    }, [dispatch])

    const onJumpClicked = (index: number) => {
        const selectedProjectId = filteredData[index].id
        navigate(`/createProcess/${selectedProjectId}`)
    }
    const onDeleteClicked = (index: number) => {
        Modal.confirm({
            title: `Do you want to delete project: ${tableData[index]?.name} ?`,
            content:
                'When clicked the OK button, ' + '\n this project will be permanently deleted and not able to recover ',
            onOk() {
                console.log('Confirm ')
                onDeleteConfirmed(index)
                // Perform the action you want to confirm here
            },
            onCancel() {
                console.log('Cancel')
            },
        })
    }

    const onDeleteConfirmed = (index: number) => {
        const selectedProjectId = filteredData[index].id
        dispatch(deleteProjectAsync(selectedProjectId))
    }
    const onSortClicked = (sortField: 'name' | 'created' | 'last') => {
        setSortOption((prevState) => {
            return {
                byName: sortField === 'name' ? nextState(prevState.byName) : undefined,
                byCreateTime: sortField === 'created' ? nextState(prevState.byCreateTime) : undefined,
                byLastModified: sortField === 'last' ? nextState(prevState.byLastModified) : undefined,
            }
        })
    }
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }
    const columns: ColumnsType<TableRow> = [
        {
            title: (
                <div className='project-list-sort-button'>
                    <span onClick={() => onSortClicked('name')}>Name</span>
                    {sortOption.byName !== undefined ? (
                        sortOption.byName ? (
                            <ArrowUpOutlined onClick={() => onSortClicked('name')} />
                        ) : (
                            <ArrowDownOutlined onClick={() => onSortClicked('name')} />
                        )
                    ) : (
                        <span></span>
                    )}
                    <Input
                        placeholder='Content Filter'
                        value={searchValue}
                        onChange={onSearch}
                        prefix={<SearchOutlined />}
                        className='project-list-filter-input'
                    />
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: (
                <div className='project-list-sort-button' onClick={() => onSortClicked('created')}>
                    Create Time
                    {sortOption.byCreateTime !== undefined ? (
                        sortOption.byCreateTime ? (
                            <ArrowUpOutlined />
                        ) : (
                            <ArrowDownOutlined />
                        )
                    ) : (
                        <div></div>
                    )}
                </div>
            ),
            dataIndex: 'createTime',
            key: 'createTime',
            render: (value: unknown, record: TableRow) => (
                <div>{record.createdTime ? new Date(record.createdTime * 1000).toLocaleString() : ''}</div>
            ),
        },
        {
            title: (
                <div className='project-list-sort-button' onClick={() => onSortClicked('last')}>
                    Last Modified Time
                    {sortOption.byLastModified !== undefined ? (
                        sortOption.byLastModified ? (
                            <ArrowUpOutlined />
                        ) : (
                            <ArrowDownOutlined />
                        )
                    ) : (
                        <div></div>
                    )}
                </div>
            ),
            dataIndex: 'lastModifiedTime',
            key: 'lastModifiedTime',
            render: (value: unknown, record: TableRow) => (
                <div>{record.lastModifiedTime ? new Date(record.lastModifiedTime * 1000).toLocaleString() : ''}</div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (value: unknown, record: TableRow, index: number) => {
                const overallIndex = (currentPage - 1) * pageSize + index
                return (
                    <div className='project-list-buttons'>
                        <Button onClick={() => onJumpClicked(overallIndex)}>
                            {tableData[index].finished ? 'Details' : 'Resume'}
                        </Button>
                        <Button type='default' icon={<DeleteOutlined />} onClick={() => onDeleteClicked(overallIndex)}>
                            Delete
                        </Button>
                    </div>
                )
            },
        },
    ]

    return (
        <div className='project-list'>
            <h1>Project List View</h1>
            <Table<TableRow>
                columns={columns}
                dataSource={sortedTableData}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page)
                        if (pageSize) setPageSize(pageSize)
                    },
                }}
            />
        </div>
    )
}
