// CongratulationPage.tsx
import React, {useEffect} from 'react'
import {Result, Button, Spin} from 'antd'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import robotGif from './image_processing20210907-13511-1juj33d.gif'
import {useAppDispatch} from '../../app/hooks'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import {getProjectByIdAsync, restartCreateProcess, triggerProjectAsync} from '../projectCreate/projectSlice'
import {useNavigate} from 'react-router-dom'
import {Project, ProjectStatus} from '../../api'
import {getProjectApi} from '../projectDetail/projectApi'
const MachineCoding: React.FC = () => {
    const dispatch = useAppDispatch()
    const project: Project | undefined = useSelector((state: RootState) => state.project.project)
    const navigate = useNavigate()

    function triggerMachineCoding() {
        if (!project) return
        dispatch(triggerProjectAsync(project.id))
    }

    useEffect(() => {
        const timer = setInterval(() => {
            if (project && project?.status?.code !== ProjectStatus.AutocodingCompleted) {
                const response = getProjectApi(project.id)
                response.then((res) => {
                    const projectInResponse = res.data
                    if (
                        projectInResponse.status.code === ProjectStatus.AutocodingCompleted &&
                        window.Notification &&
                        window.Notification.permission === 'granted'
                    ) {
                        new window.Notification(`Autocoding  for Project ${project.name} has completed`)
                        clearInterval(timer)
                        dispatch(getProjectByIdAsync(project.id))
                    }
                })
            }
        }, 5000)
    }, [])

    const onJumpClicked = (path: string) => {
        navigate(path)
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <Result
                icon={
                    <Spin tip='Loading' size='large'>
                        {' '}
                    </Spin>
                }
                title='Task has been submitted to machine coding.'
                subTitle='Please wait for the notification of completion. In the meantime, you can:'
                extra={[
                    <Button type='primary' key='continue' onClick={() => dispatch(restartCreateProcess())}>
                        Create Another Project
                    </Button>,
                    <Button key='home' onClick={() => onJumpClicked('/projects')}>
                        Go Back to project list
                    </Button>,
                ]}
            />
        </div>
    )
}

export default MachineCoding
