import {Answer, MarketingCode, Codebook, Question} from '../api'

export const ANSWER_1: Answer = {
    id: '1',
    content: 'Yes',
    count: 2,
    childrenAnswers: ['2'],
    linkedCodes: ['1'],
}
export const ANSWER_2: Answer = {
    id: '2',
    content: 'Nab, mcDonalds is better',
    count: 2,
    childrenAnswers: ['2'],
    linkedCodes: ['2'],
}

export const ANSWER_3: Answer = {
    id: '3',
    content: 'swisse, coca-cola, maccas',
    count: 2,
    childrenAnswers: ['3'],
    linkedCodes: ['3', '4', '5'],
}

export const QUESTION: Question = {
    id: '1',
    content: 'Which sponsor or brand comes to mind first for AFL (Australian Rules)?',
    answers: [ANSWER_1, ANSWER_2, ANSWER_3],
}

export const MISSING_DATA: MarketingCode = {
    code: '0',
    count: 3,
    codeName: 'Missing Data',
    confidenceLevel: 0.5,
    percentile: 0.3,
}

export const MCDONALDS: MarketingCode = {
    code: '1',
    count: 3,
    codeName: 'McDonalds',
    confidenceLevel: 0.5,
    percentile: 0.1,
}

export const CARLTON_DRAUGHT: MarketingCode = {
    code: '2',
    count: 3,
    codeName: 'Carlton Draught',
    confidenceLevel: 0.6,
    percentile: 0.1,
}
export const SHERRIN: MarketingCode = {
    code: '3',
    count: 3,
    codeName: 'Sherrin',
    confidenceLevel: 0.7,
    percentile: 0.2,
}

export const VIRGIN_AUSTRALIA: MarketingCode = {
    code: '4',
    count: 3,
    codeName: 'Virgin Australia',
    confidenceLevel: 0.7,
    percentile: 0.2,
}
export const CODEBOOK: Codebook = {
    id: '1',
    codes: [MISSING_DATA, MCDONALDS, CARLTON_DRAUGHT, SHERRIN, VIRGIN_AUSTRALIA],
}
