import {Spin} from 'antd'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import './LoadingSpiner.css'

function LoadingSpinner() {
    const isLoading = useSelector((state: RootState) => state.project.isLoading || state.user.isLoading)

    if (isLoading) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                    background: 'rgba(255, 255, 255, 0.8)',
                }}
            >
                <Spin size='large' />
            </div>
        )
    }
    return <div></div>
}

export default LoadingSpinner
