import {ProjectRequestBody, ProjectLanguageEnum, ProjectProjectTypeEnum} from '../../api'
import React, {FC} from 'react'
import {Form, Input, Select, Button} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import './ProjectCreate.css'
import {useAppDispatch} from '../../app/hooks'
import {createProjectAsync} from './projectSlice'

const {Option} = Select

// 3. Add types to the component and its props

const CreateProjectForm: FC = () => {
    const dispatch = useAppDispatch()

    const onFinish = async (values: ProjectRequestBody) => {
        console.log('Form values: ', values)
        dispatch(createProjectAsync(values))
    }

    return (
        <div className='form-page'>
            <h1>Create a Project</h1>
            <Form layout='vertical' onFinish={onFinish}>
                {/* 4. Add types to the form fields and event handlers */}
                <Form.Item
                    label='Project Name'
                    name='name'
                    rules={[{required: true, message: 'Please input the project name!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Project Type'
                    name='projectType'
                    rules={[{required: true, message: 'Please select the project type!'}]}
                >
                    <Select placeholder='Select a project type'>
                        {Object.entries(ProjectProjectTypeEnum).map(([key, value]) => (
                            <Option key={key} value={value}>
                                {value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label='Language'
                    name='language'
                    rules={[{required: true, message: 'Please select the language!'}]}
                >
                    <Select placeholder='Select a language'>
                        {Object.entries(ProjectLanguageEnum).map(([key, value]) => (
                            <Option key={key} value={value}>
                                {value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' icon={<PlusOutlined />}>
                        Create Project
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default CreateProjectForm
