import React, {useState} from 'react'
import type, {Button, Col, Row, Select} from 'antd'
import CodeBookUI from './Codebook'
import {Content} from 'antd/es/layout/layout'
import AnswersView from './Answer'
import {QUESTION} from '../../assets/test-data'

import './ManualCoding.css'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import {CloudDownloadOutlined} from '@ant-design/icons'
import {downloadProjectApi, downloadProjectCodeBookApi} from '../projectCreate/projectApi'
import {useAppDispatch} from '../../app/hooks'
import {putProjectAsync, upsertProjectQuestionAsync} from '../projectCreate/projectSlice'
import {Question} from '../../api'

const {Option} = Select

export const ManualCoding: React.FC = () => {
    const dispatch = useAppDispatch()
    const {project, codeBook, locallyUpdated} = useSelector((state: RootState) => state.project)

    if (!project || !project.questions || project.questions.length === 0) {
        return <div></div>
    }

    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)

    const handleChange = (index: number) => {
        if (project.questions && project.questions[index]) {
            setSelectedQuestionIndex(index)
        }
    }

    const handleSave = () => {
        if (!project.questions) return
        const currentQuestion: Question = project.questions[selectedQuestionIndex]
        dispatch(
            upsertProjectQuestionAsync({
                projectId: project.id,
                payload: project.questions,
                codebookId: codeBook?.id,
                modelGeneratedCodebook: codeBook,
            }),
        )
    }

    const handleDownloadProjectDataFile = () => {
        if (!project.questions) return
        downloadProjectApi(project.id, project.questions[selectedQuestionIndex].id)
    }

    const handleDownloadCodebook = () => {
        if (!project.questions) return
        downloadProjectCodeBookApi(project.questions[selectedQuestionIndex].modelGeneratedCodebook?.id)
    }

    return (
        <Content>
            <Row>
                <Col span={24} className='manual-coding-header'>
                    <div>
                        <h1>{QUESTION.content}</h1>
                        <Select defaultValue={0} onChange={handleChange}>
                            {project.questions.map((question, index) => {
                                if (question.content)
                                    return (
                                        <Option key={index} value={index}>
                                            {question.content}
                                        </Option>
                                    )
                            })}
                        </Select>
                    </div>
                    <div className='manual-coding-btn-section'>
                        <Button
                            type='primary'
                            className='manual-coding-action-btn'
                            onClick={handleSave}
                            disabled={!locallyUpdated}
                        >
                            Save
                        </Button>
                        <Button
                            type='primary'
                            className='manual-coding-action-btn'
                            icon={<CloudDownloadOutlined />}
                            onClick={handleDownloadProjectDataFile}
                        >
                            Download Project DataFile
                        </Button>
                        <Button
                            type='primary'
                            className='manual-coding-action-btn'
                            icon={<CloudDownloadOutlined />}
                            onClick={handleDownloadCodebook}
                        >
                            Download Codebook
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={12} className='manual-coding-half'>
                    <AnswersView selectedQuestion={project.questions[selectedQuestionIndex]} codebook={codeBook} />
                </Col>
                <Col span={12} className='manual-coding-half'>
                    <CodeBookUI codebook={codeBook} preTickedCode={[]} />
                </Col>
            </Row>
        </Content>
    )
}
