import {ColumnType, FileExchangeUrl} from '../api'
import axios from 'axios'

export function delay(time: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, time))
}

export function getColumnType(columnTypeString: string): ColumnType {
    switch (columnTypeString) {
        case 'NUMERIC':
            return ColumnType.Numeric
        case 'TEXT':
            return ColumnType.Text
        case 'DATE':
            return ColumnType.Date
        default:
            return ColumnType.Other
    }
}
export function putFileToS3PresignedUrl(file: File, url: string) {
    const formData = new FormData()
    formData.append('file', file)
    return axios.put(url, file, {
        headers: {
            'Content-Type': file.type,
        },
    })
}

export function downloadFile(url: string) {
    // Create a new 'a' element, click it to download the file
    const link = document.createElement('a')
    link.href = url
    link.download = 'filename' // Optional: Set a filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export function nextState(currentState: boolean | undefined): boolean | undefined {
    switch (currentState) {
        case undefined:
            return true
        case true:
            return false
        case false:
            return undefined
        default:
            return undefined
    }
}
