import React, {FC} from 'react'
import {Upload, Button, message} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import {RcFile} from 'antd/lib/upload'
import {AppDispatch, RootState} from '../../app/store'
import {useDispatch, useSelector} from 'react-redux'
import {uploadDataFileAsync} from '../projectCreate/projectSlice'

// 2. Create a file upload component
const FileUpload: FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const currentProject = useSelector((state: RootState) => state.project.project)

    const beforeUpload = (file: RcFile) => {
        // Add any validation or processing logic here
        return false // Return false to prevent auto uploading
    }

    const onDrop = (info: any) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`)
        }
        dispatch(uploadDataFileAsync({projectId: currentProject?.id ? currentProject.id : '', file: info.file}))
    }

    // 3. Add the Upload component from Ant Design
    // 4. Configure the Upload component to support file drop and open file explorer
    return (
        <div>
            <h1>File Upload</h1>
            <Upload.Dragger
                name='file'
                multiple={true}
                beforeUpload={beforeUpload}
                onChange={onDrop}
                showUploadList={false}
            >
                <p className='ant-upload-drag-icon'>
                    <UploadOutlined />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='ant-upload-hint'>Support for a single or bulk upload.</p>
            </Upload.Dragger>
            <Button
                type='primary'
                onClick={() => {
                    document.getElementById('fileInput')?.click()
                }}
            >
                Open File Explorer
            </Button>
            <input
                id='fileInput'
                type='file'
                multiple
                style={{display: 'none'}}
                onChange={(e) => {
                    // Handle file selection from file explorer
                    console.log(e.target.files)
                }}
            />
        </div>
    )
}

export default FileUpload
