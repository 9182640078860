import React from 'react'
import {Layout, Menu, Avatar, Space} from 'antd'
import {Link} from 'react-router-dom'
import {DatabaseFilled, HomeOutlined, UserOutlined} from '@ant-design/icons'
import './Header.css'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'

const {Header} = Layout

export const TopHeader: React.FC = () => {
    const project = useSelector((state: RootState) => state.project).project
    const user = useSelector((state: RootState) => state.user.user)
    return (
        <Layout>
            <Header className='top-header'>
                <Link to='/'>
                    <div className='logo'>
                        <DatabaseFilled className='logo-icon' /> <h3 className='logo-text'>Auto Coding</h3>
                    </div>
                </Link>
                <Menu theme='dark' mode='horizontal' defaultSelectedKeys={['1']} className='top-menu'>
                    <Menu.Item key='1' icon={<HomeOutlined />}>
                        <Link to='/'>Home</Link>
                    </Menu.Item>
                    <Menu.Item key='2' icon={<UserOutlined />}>
                        <Link to='/projects'>Project List</Link>
                    </Menu.Item>
                    <Menu.Item key='3' icon={<UserOutlined />}>
                        <Link to='/createProcess'> Create Project </Link>
                    </Menu.Item>
                </Menu>
                <Space>
                    <Avatar icon={<UserOutlined />} />
                    <span style={{color: 'white'}}>{user?.username}</span>
                </Space>
            </Header>
        </Layout>
    )
}
