import React, {useState} from 'react'
import {Button, Slider, Typography} from 'antd'
import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {useAppDispatch} from '../../app/hooks'
import {PutProjectRequestBody, updateProjectAsync} from '../projectCreate/projectSlice'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import './SetParameter.css'
const {Title} = Typography

const ParameterSliders: React.FC = () => {
    const project = useSelector((state: RootState) => state.project.project)
    const [minCount, setMinCount] = useState(project?.minimalCount ? project.minimalCount : 2)
    const [similarityLevel, setSimilarityLevel] = useState(project?.similarityLevel ? project.similarityLevel : 65)
    const dispatch = useAppDispatch()

    const onSubmitClick = () => {
        if (!project) return
        const request: PutProjectRequestBody = {
            ...project,
            minimalCount: minCount,
            similarityLevel: similarityLevel,
        }
        dispatch(updateProjectAsync(request))
    }
    return (
        <div style={{padding: '2rem'}}>
            <Title level={3}>Minimum Count: {minCount}</Title>
            <Slider
                className='parameter-slider'
                min={2}
                max={30}
                value={minCount}
                onChange={(value: number) => setMinCount(value)}
                marks={{
                    2: <MinusCircleOutlined />,
                    30: <PlusCircleOutlined />,
                }}
            />
            <Title level={3}>Similarity Level: {similarityLevel}</Title>
            <Slider
                min={65}
                className='parameter-slider'
                max={100}
                value={similarityLevel}
                onChange={(value: number) => setSimilarityLevel(value)}
                marks={{
                    65: <MinusCircleOutlined />,
                    100: <PlusCircleOutlined />,
                }}
            />
            <Button onClick={() => onSubmitClick()} type='primary'>
                Set Parameters
            </Button>
        </div>
    )
}

export default ParameterSliders
