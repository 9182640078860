import React, {useCallback, useRef, useState} from 'react'
import {Divider, Checkbox, Input, Button, Modal} from 'antd'
import {FixedSizeList as List} from 'react-window'
import {Codebook, AutocodingResultInner, CodebookCode} from '../../api'
import Tiles from '../../components/Tiles/Tiles'
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    SearchOutlined,
} from '@ant-design/icons'

import './Codebook.css'
import {CheckboxChangeEvent} from 'antd/es/checkbox'
import {updateCodebook} from '../projectCreate/projectSlice'
import {useAppDispatch} from '../../app/hooks'
import {nextState} from '../../utils/commonUtils'

interface CodebookProps {
    codebook?: Codebook
    preTickedCode?: string[]
    onSelectUpdate?: (selectedCode: {[p: string]: boolean}) => void
    onCodeMerge?: (keep: string, remove: string[]) => void
    inModal?: boolean
    modelTitle?: string
    onConfirm?: () => void
    modelRef?: React.RefObject<HTMLDivElement>
}

const SPECIAL_CODE_OTHER = 'X'
const renderRow = ({
    index,
    style,
    data,
}: {
    index: number
    style: React.CSSProperties
    data: {
        filteredCodes: CodebookCode[]
        editingIndex: number | null
        newCode: string
        editingText: string
        handleCodeInputChange: (e: {target: {value: React.SetStateAction<string>}}) => void
        handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
        confirmEdit: (index: number) => void
        setEditingIndex: (index: number | null) => void
        checked: {[key: string]: boolean}
        onChange: (e: CheckboxChangeEvent, code: string) => void
        updateCode: (code: string) => void
        updateEditingText: (text: string) => void
        onDeleteConfirm: (unwantedCode: AutocodingResultInner) => void
    }
}) => {
    const {
        filteredCodes,
        editingIndex,
        newCode,
        editingText,
        handleCodeInputChange,
        handleInputChange,
        confirmEdit,
        setEditingIndex,
        checked,
        onChange,
        updateCode,
        updateEditingText,
        onDeleteConfirm,
    } = data
    const item: CodebookCode = filteredCodes[index]
    const code = item.code
    const message = [...(filteredCodes ?? [])]
        .filter((item, i) => i !== editingIndex)
        .map((c) => c.code?.toString())
        .includes(newCode)
        ? 'Code already exist.'
        : ''

    const onDeleteClick = (index: number, item: CodebookCode) => {
        Modal.confirm({
            title: 'Do you want to delete this code?',
            content:
                `code: ${item.code}, ` +
                `\n codename: ${item.codeName}.` +
                '\n When clicked the OK button, ' +
                '\n this codes will be deleted ' +
                '\n and the linked answer will be merged to code "Other".',
            onOk() {
                console.log('Delete Confirmed')
                onDeleteConfirm(item)
                // Perform the action you want to confirm here
            },
            onCancel() {
                console.log('Delete Canceled')
            },
        })
    }

    if (code) {
        return (
            <div className='codebook-item' style={style}>
                {editingIndex === index ? (
                    <div>
                        <div className='codebook-item-left'>
                            <div>
                                <span>Code:</span>
                                <input
                                    name='newCode'
                                    value={newCode}
                                    className='codebook-item-code-input'
                                    onChange={handleCodeInputChange}
                                />
                            </div>
                            <div>
                                <span>Code Name:</span>
                                <input
                                    name='editingText'
                                    value={editingText}
                                    className='codebook-item-name-input'
                                    onChange={handleInputChange}
                                />
                            </div>
                            <Button
                                className='codebook-item-button'
                                icon={<CheckOutlined />}
                                disabled={message !== ''}
                                onClick={() => confirmEdit(index)}
                            />
                            <Button
                                className='codebook-item-button'
                                icon={<CloseOutlined />}
                                onClick={() => setEditingIndex(null)}
                            />
                        </div>
                        <div className='codebook-editing-message'>{message}</div>
                    </div>
                ) : (
                    <div className='codebook-item-left'>
                        <Checkbox
                            className='codebook-item-check-box'
                            checked={checked[code] || false}
                            onChange={(e) => onChange(e, code)}
                        ></Checkbox>
                        <Tiles n={code} />
                        <label className='codebook-item-name'>{item.codeName}</label>
                    </div>
                )}
                <div className='codebook-item-right'>
                    {item.isNewCode ? <span className='codebook-item-new'>New</span> : <></>}
                    {editingIndex === index ? (
                        <></>
                    ) : (
                        <div>
                            <Button
                                className='codebook-item-row-button'
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setEditingIndex(index)
                                    updateCode(item.code ?? '')
                                    updateEditingText(item.codeName ?? '')
                                }}
                            />
                            <Button
                                className='codebook-item-row-button'
                                icon={<CloseOutlined />}
                                onClick={() => onDeleteClick(index, item)}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
}

const CodebookUI: React.FC<CodebookProps> = (props) => {
    const dispatch = useAppDispatch()
    const [checked, setChecked] = useState<{[key: string]: boolean}>(
        props.preTickedCode
            ? props.preTickedCode.reduce((obj: {[p: string]: boolean}, num) => {
                  obj[num] = true
                  return obj
              }, {})
            : {},
    )
    const [filter, setFilter] = useState({content: '', code: ''})
    const [editingIndex, setEditingIndex] = useState<number | null>(null)
    const [editingText, setEditingText] = useState<string>('')
    const [isAdding, setIsAdding] = useState<boolean>(false)
    const [newCode, setNewCode] = useState<string>('')
    const [sortOption, setSortOption] = useState<{byContent: boolean | undefined; byCode: boolean | undefined}>({
        byContent: undefined,
        byCode: true,
    })
    const actionHeaderRef = useRef<HTMLDivElement>(null)
    const codebookCodes = props.codebook?.codes

    const toggleChecked = (code: string) => {
        if (props.onSelectUpdate) {
            props?.onSelectUpdate({...checked, [code]: !checked[code]})
        }
        setChecked((prevState) => ({...prevState, [code]: !prevState[code]}))
    }

    const onChange = (e: CheckboxChangeEvent, code: string) => {
        toggleChecked(code)
    }

    const distinctCodeBook: AutocodingResultInner[] = []
    const distinctCodes = new Set()
    codebookCodes?.forEach((i: AutocodingResultInner) => {
        if (!distinctCodes.has(i.code)) {
            distinctCodeBook.push(i)
            distinctCodes.add(i.code)
        }
    })
    const otherCode: AutocodingResultInner = getOtherCode(distinctCodeBook)
    const filteredCodes = distinctCodeBook
        ? distinctCodeBook
              .filter((item: AutocodingResultInner) => {
                  if (item.code && checked[item.code]) {
                      return true
                  }
                  if (item.codeName) {
                      return item.codeName.toLowerCase().includes(filter.content.toLowerCase())
                  } else {
                      return false
                  }
              })
              .filter((item: AutocodingResultInner) => {
                  if (item.code) {
                      if (checked[item.code]) {
                          return true
                      }
                      return item.code.toString().toLowerCase().includes(filter.code.toLowerCase())
                  } else {
                      return false
                  }
              })
              .sort(function (a, b) {
                  if (sortOption.byCode !== undefined) {
                      const aCode = a.code ? parseInt(a.code) : 0
                      const bCode = b.code ? parseInt(b.code) : 0

                      return sortOption.byCode ? aCode - bCode : bCode - aCode
                  }
                  if (sortOption.byContent !== undefined) {
                      return sortOption.byContent
                          ? (a.codeName ?? '').toLowerCase().localeCompare(b.codeName ?? '')
                          : (b.codeName ?? '').toLowerCase().localeCompare(a.codeName ?? '')
                  }
                  return 0
              })
        : []
    const onMergeClick = () => {
        const codesToMerge = Object.entries(checked)
            .map(([key, value]) => {
                if (value) {
                    return `${key} - ${codebookCodes?.find((item) => item.code === key)?.codeName ?? ''}`
                }
            })
            .join(', ')
        Modal.confirm({
            title: `Do you want to merge ${codesToMerge} ?`,
            content:
                'When clicked the OK button, ' +
                '\n these codes will be merge into the code with smallest code number, ' +
                '\n the rest will be deleted!',
            onOk() {
                console.log('Confirm ')
                onMergeConfirm()
                // Perform the action you want to confirm here
            },
            onCancel() {
                console.log('Cancel')
            },
        })
    }

    const onDeleteConfirm = (unwantedCode: AutocodingResultInner) => {
        let newCodes = filteredCodes.filter((value) => value.code != unwantedCode.code)
        newCodes = otherCode.code === SPECIAL_CODE_OTHER ? [...newCodes, otherCode] : [...newCodes]

        dispatch(
            updateCodebook({
                newCodes: newCodes,
                keep: otherCode.code,
                remove: [unwantedCode.code],
            }),
        )
    }

    const onMergeConfirm = () => {
        const checkedCodes: AutocodingResultInner[] = []
        const uncheckedCodes: AutocodingResultInner[] = []
        distinctCodeBook.forEach((item: AutocodingResultInner) => {
            if (item.code && checked[item.code]) {
                checkedCodes.push(item)
            } else {
                uncheckedCodes.push(item)
            }
        })
        const newCodes = [...uncheckedCodes]
        newCodes.push(checkedCodes[0])
        if (checkedCodes[0].code) {
            setChecked({})
        }
        if (checkedCodes[0].code) {
            dispatch(
                updateCodebook({
                    newCodes: newCodes,
                    keep: checkedCodes[0].code.toString(),
                    remove: checkedCodes
                        .slice(-(checkedCodes.length - 1))
                        .map((item) => item.code?.toString())
                        .filter((item): item is string => item !== undefined),
                }),
            )
        }
    }

    const confirmEdit = (index: number) => {
        const codeUpdating = filteredCodes[index]
        const newCodes = [...(codebookCodes ?? [])]
        const updatedCodes: AutocodingResultInner[] = newCodes.map((item, i) => {
            if (newCodes[i].code === codeUpdating.code) {
                return {
                    ...newCodes[i],
                    codeName: editingText,
                    code: newCode,
                }
            }
            return item
        })
        dispatch(
            updateCodebook({
                newCodes: updatedCodes,
                keep: newCode,
                remove: [codeUpdating.code?.toString()],
            }),
        )
        setEditingIndex(null)
        setEditingText('')
        setNewCode('')
    }

    const handleContentFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({content: e.target.value, code: filter.code})
    }

    const handleCodeFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({content: filter.content, code: e.target.value})
    }

    const addNewCode = () => {
        const newCodebookCode = {
            code: newCode,
            codeName: editingText,
            count: 0,
        }
        const newCodes = [...(codebookCodes ?? []), newCodebookCode]

        dispatch(
            updateCodebook({
                newCodes: newCodes,
                keep: newCode,
                remove: [],
            }),
        )
        setEditingIndex(null)
        setEditingText('')
        setNewCode('')
    }

    const handleCodeInputChange = useCallback((e: {target: {value: React.SetStateAction<string>}}) => {
        setNewCode(e.target.value)
    }, [])

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target

        switch (name) {
            case 'newCode':
                setNewCode(value)
                break
            case 'editingText':
                setEditingText(value)
                break
            default:
                break
        }
    }, [])

    const updateEditingText = (e: string) => {
        setEditingText(e)
    }
    const updateCode = (e: string) => {
        setNewCode(e)
    }
    const message = [...(filteredCodes ?? [])]
        .filter((item, i) => i !== editingIndex)
        .map((c) => c.code?.toString())
        .includes(newCode)
        ? 'Code already exist.'
        : ''

    return (
        <>
            <h2>Code Book</h2>
            <Divider />
            <div ref={actionHeaderRef} className='fixed-action-header'>
                {!props.inModal ? (
                    <div>
                        <h3>Actions</h3>
                        <div className='codebook-action-buttons'>
                            <Button
                                className='codebook-button'
                                type='primary'
                                onClick={onMergeClick}
                                disabled={Object.values(checked).filter((value) => value).length < 2}
                            >
                                Merge
                            </Button>
                            <Button
                                type='primary'
                                className='codebook-button answer-button-uncheck'
                                onClick={() => setChecked({})}
                                disabled={Object.values(checked).filter((value) => value).length === 0}
                            >
                                Uncheck All
                            </Button>

                            <Button
                                type={sortOption.byCode === undefined ? 'default' : 'primary'}
                                className='codebook-button answer-button-sort'
                                onClick={() =>
                                    setSortOption({
                                        byCode: nextState(sortOption.byCode),
                                        byContent: undefined,
                                    })
                                }
                            >
                                Sort by Code{' '}
                                {sortOption.byCode !== undefined ? (
                                    sortOption.byCode ? (
                                        <ArrowUpOutlined />
                                    ) : (
                                        <ArrowDownOutlined />
                                    )
                                ) : (
                                    <div></div>
                                )}
                            </Button>

                            <Button
                                type={sortOption.byContent === undefined ? 'default' : 'primary'}
                                className='codebook-button answer-button-sort'
                                onClick={() =>
                                    setSortOption({
                                        byCode: undefined,
                                        byContent: nextState(sortOption.byContent),
                                    })
                                }
                            >
                                Sort by Name{' '}
                                {sortOption.byContent !== undefined ? (
                                    sortOption.byContent ? (
                                        <ArrowUpOutlined />
                                    ) : (
                                        <ArrowDownOutlined />
                                    )
                                ) : (
                                    <div></div>
                                )}
                            </Button>
                            {isAdding ? (
                                <>
                                    <h3>Add New Code</h3>
                                    <div>
                                        <span>Code:</span>
                                        <input value={newCode} onChange={(e) => setNewCode(e.target.value)} />
                                    </div>
                                    <div>
                                        <span>Code Name:</span>
                                        <input value={editingText} onChange={(e) => setEditingText(e.target.value)} />
                                        <Button
                                            icon={<CheckOutlined />}
                                            onClick={addNewCode}
                                            disabled={message != ''}
                                        />
                                        <Button icon={<CloseOutlined />} onClick={() => setIsAdding(false)} />
                                        <div className='codebook-editing-message'>{message}</div>
                                    </div>
                                </>
                            ) : (
                                <Button className='codebook-button' type='primary' onClick={() => setIsAdding(true)}>
                                    Add New Code
                                </Button>
                            )}
                        </div>
                        <Divider />
                    </div>
                ) : (
                    <div>
                        <h3>Updating code for answer {props.modelTitle}</h3>
                        <Divider />
                    </div>
                )}
                <div className='answer-filter-section'>
                    <Input
                        placeholder='Code Filter'
                        value={filter.code}
                        onChange={handleCodeFilterChange}
                        prefix={<SearchOutlined />}
                        className='answer-filter-input'
                    />

                    <Input
                        placeholder='Content Filter'
                        value={filter.content}
                        onChange={handleContentFilterChange}
                        prefix={<SearchOutlined />}
                        className='answer-filter-input'
                    />
                </div>
                <Divider />
                <div className='selected-codes'>
                    Selected Codes:
                    <div className='checked-tiles-row'>
                        {Object.entries(checked).map(([key, value]) => {
                            if (value) {
                                return <Tiles key={key} n={key} closeButtonCallback={() => toggleChecked(key)} />
                            }
                        })}
                    </div>
                    {props.inModal ? (
                        <div>
                            <Button type='primary' onClick={() => props.onConfirm?.()}>
                                Ok
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <Divider />
            </div>

            <List
                height={props.inModal ? 300 : 500}
                itemData={{
                    filteredCodes,
                    editingIndex,
                    newCode,
                    editingText,
                    handleCodeInputChange,
                    handleInputChange,
                    confirmEdit,
                    setEditingIndex,
                    checked,
                    onChange,
                    updateCode,
                    updateEditingText,
                    onDeleteConfirm,
                }}
                width={props.inModal ? 480 : 700}
                itemCount={filteredCodes.length}
                itemSize={35}
            >
                {renderRow}
            </List>
        </>
    )
}

function getOtherCode(codes: AutocodingResultInner[]): AutocodingResultInner {
    const otherCode = codes.find((value) => value.codeName === 'Others')
    return otherCode || {code: SPECIAL_CODE_OTHER, codeName: 'Others'}
}

export default CodebookUI
