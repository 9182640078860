import axios from 'axios'
import {API_BASE_URL} from '../../constant'
import {getAuthHeader} from '../../utils/apiClient'

export async function projectListApi() {
    return axios.get(`${API_BASE_URL}/projects`, getAuthHeader())
}
export async function deleteProjectApi(projectId: string) {
    return axios.delete(`${API_BASE_URL}/projects/${projectId}`, getAuthHeader())
}
