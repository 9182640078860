import axios from 'axios'
import {API_BASE_URL, AUTH_REDIRECT_URI} from '../../constant'
import {getAuthHeader} from '../../utils/apiClient'

export function getToken(amount = 1) {
    return new Promise<{data: number}>((resolve) => setTimeout(() => resolve({data: amount}), 3000))
}

export async function fetchToken() {
    const authorisationCode = getTokenFromUrl()
    return await axios.post(
        `${API_BASE_URL}/auth/token`,
        {
            authorisationCode,
            redirectUri: AUTH_REDIRECT_URI,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )
}

export function getTokenFromUrl(): string | null {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const queryParams = url.searchParams
    return queryParams.get('code')
}

export function getUserDetail() {
    return axios.get(`${API_BASE_URL}/users/me`, getAuthHeader())
}
